import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

const useStore = createStore({
    background: "#0099FF",
})

export function withRotate(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                animate={{ rotate: 90 }}
                transition={{ duration: 2 }}
            />
        )
    }
}

export function withHover(Component): ComponentType {
    return (props) => {
        return <Component {...props} whileHover={{ scale: 1.05 }} />
    }
}

export function withRandomColor(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        return (
            <Component
                {...props}
                animate={{
                    background: store.background,
                }}
                onClick={() => {
                    setStore({ background: randomColor() })
                }}
            />
        )
    }
}

// Setting Maximum Text Character Limit in Framer CMS by Doruk Kavcioglu

// Framer.guide | Framer Course - Coming Soon

export function withCharacterLimit(Component): ComponentType {
    return (props) => {
        // Extract text property
        const { text, ...otherProps } = props
        const limit = 60 // Set character limit

        // Check if text is longer than limit, if so, trim it
        let trimmedText = text
        if (text?.length > limit) {
            trimmedText = text.slice(0, limit).trim() // Trim extra spaces

            // Check if trimmed text ends with dot
            if (trimmedText.endsWith(".")) {
                trimmedText = trimmedText.slice(0, -1).trim() // Remove extra dot if it ends with a dot
            }

            trimmedText += "..." // Add three dots
        }

        return <Component {...otherProps} text={trimmedText} />
    }
}
